.navbar {
  display: flex;
  justify-content: space-between;
}

.navbar-list-items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 56px;
}

.navbar-list-item {
  list-style: none;
}

.navbar-list-item a,
.navbar-list-item span {
  text-decoration: none;
  color: #000;
  font-family: AvenirBlack;
  font-size: 18px;
  font-weight: 350;
  transition: 0.2s ease-in-out;
}

.navbar-list-item a:hover {
  color: var(--rose);
}

.status-indicator {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #41ffaf;
  margin-right: 8px;
}

.navbar-list-item-contact {
  font-size: 1rem;
  list-style: none;
}

.navbar-list-item-contact a {
  border: 1px solid #000;
  border-radius: 24px;
  padding: 0.75rem 1rem;
  background-color: #fff;
  cursor: pointer;
  font-family: AvenirBlack;
  font-weight: 350;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
}

.navbar-list-item-contact a:hover {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.navbar-list-item-contact__blink {
  animation: blink-animation 0.5s steps(5, start) infinite;
  -webkit-animation: blink-animation 0.5s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.active-link {
  color: var(--rose) !important;
  font-weight: 750;
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 12px;
  position: relative;
}

.active-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #c95d63;
  height: 8px;
  border-radius: 8px;
}

#navbar__burger-menu {
  width: 22px;
  height: 16px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#navbar__burger-menu span {
  display: block;
  position: absolute;
  height: 2.5px;
  width: 100%;
  background: #000000;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#navbar__burger-menu span:nth-child(1) {
  top: 0px;
}

#navbar__burger-menu span:nth-child(2),
#navbar__burger-menu span:nth-child(3) {
  top: 6px;
}

#navbar__burger-menu span:nth-child(4) {
  top: 12px;
}

#navbar__burger-menu.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#navbar__burger-menu.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#navbar__burger-menu.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#navbar__burger-menu.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.navbar-list-items__mobile {
  position: absolute;
  left: 0px;
  top: 130px;
  width: 100% !important;
  background-color: #f9f9f9;
  margin: 0;
  /* display: -ms-grid;
  display: grid;
  grid-template-rows: 1fr repeat(4, 0.5fr);
  grid-row-gap: 25px; */
  padding: 0;
  list-style: none;
  clear: both;
  width: auto;
  text-align: center;
  height: 0px;
  overflow: hidden;
  transition: height 0.4s ease;
  z-index: 120;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.navbar-list-items__mobile-open {
  border-top: 1px solid #0a74a6;
  height: calc(100vh - 130px);
}

.navbar-list-items__mobile .navbar-list-items__mobile-menus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 15px 16px 25px;
  overflow-y: auto;
}

.navbar-list-items__mobile
  .navbar-list-items__mobile-menus
  .navbar-list-items__mobile-menus-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-direction: column;
}

.active-link-mobile {
  color: var(--rose) !important;
  font-weight: 750;
  font-size: 18px;
  line-height: 22px;
  padding-bottom: 12px;
  position: relative;
}

/* MaterialUI breakpoint `sm` */
@media (max-width: 600px) {
  .navbar-list-items__mobile {
    top: 84px;
  }
  .navbar-list-items__mobile-open {
    height: calc(100vh - 84px);
  }
}
