.password-protected {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  background-image: url('/public/images/password_protected_bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  z-index: 10000;
}

.password-protected-content {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  background: #f5f5f5;
  border: 1px solid #2f2c2f;
}

.password-protected-content-label {
  display: flex;
  flex-direction: column;
}

.password-protected-content h3 {
  font-weight: 750;
  font-size: 32px;
  line-height: 38px;
  color: var(--charcoal);
  font-family: 'AvenirBlack';
  margin-bottom: 8px;
}

.password-protected-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--charcoal);
  margin-bottom: 8px;
}

.password-protected-content button {
  padding: 12px 32px;
  background: #f9f9f9;
  border: 1px solid #2f2c2f;
  border-radius: 24px;
  font-weight: 750;
  font-size: 18px;
  line-height: 22px;
  color: var(--dark-magenta);
  width: 100%;
}

.password-protected-content button:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 2px 4px, rgba(0, 0, 0, 0.23) 0px 3px 3px;
}

.password-protected-input {
  width: 100%;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #2f2c2f;
}

.password-protected-input-focused {
  border: 2px solid var(--blue);
  box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.01),
    0px 0px 8px rgba(0, 0, 0, 0.2);
}

.password-protected-input input {
  width: 100%;
  border: none;
  font-size: 16px;
}

.password-protected-input input:focus {
  outline: none;
}

.password-protected-input__show-password:hover {
  cursor: pointer;
}

@media (max-width: 600px) {
  .password-protected {
    padding: 0 10px;
  }
  .password-protected-content {
    padding: 32px 16px;
    /* gap: 8px; */
    justify-content: center;
    align-items: center;
  }
  .password-protected-content h3 {
    font-size: 26px;
    text-align: center;
  }
  .password-protected-content p {
    font-size: 14px;
    text-align: center;
  }
  .password-protected-content button {
    font-size: 14px;
  }
  .password-protected-input {
    padding: 8px 12px;
  }

  .password-protected-input input {
    font-size: 14px;
  }
}
