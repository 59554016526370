.show-more {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 32px;
  background: #f9f9f9;
  border: 1px solid #2f2c2f;
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.45);
  border-radius: 24px;
  font-weight: 750;
  font-size: 18px;
  line-height: 22px;
  margin-top: 32px;
}

.show-more:hover {
  cursor: pointer;
}
