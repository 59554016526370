:root {
  --black: #000;
  --white: #fff;
  --charcoal: #474747;
  --slate-blue: #25283d;
  --dark-magenta: #2f2c2f;
  --dark-blue: #25283d;
  --rose: #c95d63;
  --gray: #808080;
  --blue: #0a74a6;
}

.jm-typography {
  font-family: 'AvenirBlack';
  font-style: normal;
}

.jm-typography-2 {
  font-family: 'AvenirRoman';
  font-style: normal;
}

.heading-level-1 {
  font-weight: 750;
  font-size: 88px;
  line-height: 105px;
  color: var(--charcoal);
}

.heading-level-2 {
  font-weight: 750;
  font-size: 72px;
  line-height: 86px;
  color: var(--charcoal);
}

.heading-level-3 {
  font-weight: 400;
  font-size: 32px;
  line-height: 43px;
  text-align: center;
  color: var(--slate-blue);
}

.heading-level-4 {
  font-weight: 350;
  font-size: 28px;
  line-height: 32px;
  color: var(--charcoal);
}

.heading-level-4-card {
  font-weight: 750;
  font-size: 26px;
  line-height: 31px;
  color: var(--charcoal);
}

.body-1 {
  font-weight: 350;
  font-size: 26px;
  line-height: 31px;
  color: var(--slate-blue);
}

.body-2 {
  font-weight: 350;
  font-size: 24px;
  line-height: 29px;
  color: var(--charcoal);
}

.body-3 {
  font-weight: 350;
  font-size: 21px;
  line-height: 27px;
  color: var(--charcoal);
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.text-white {
  color: var(--white);
}

.font-family-black {
  font-family: 'AvenirBlack';
}
