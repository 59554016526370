.footer * {
  color: var(--white);
}

.footer {
  background-color: var(--dark-blue);
  padding: 56px 0;
  margin-top: 112px;
}

.footer__first-row {
  display: flex;
  justify-content: space-between;
}

.footer__second-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 39px;
}

.footer__second-row div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer__second-row div p {
  font-size: 13px;
  line-height: 15px;
}

.footer__message {
  width: 300px;
}

.footer__message h4 {
  font-weight: 600;
  padding-bottom: 16px;
  font-size: 28px;
  font-family: 'AvenirBlack';
}

.footer__message p {
  font-size: 21px;
  font-weight: 600;
  line-height: 25px;
  font-family: 'AvenirBook';
}

.footer__social-media {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer__social-media p {
  font-size: 18px;
  line-height: 21px;
}

.footer__social-media-icons {
  border-top: 1px dashed #fff4e9;
  padding-top: 20px;
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 30px;
}

/* .footer__social-media-icons i {
  font-size: 30px;
} */

.footer__social-media-icons img:hover {
  cursor: pointer;
}

.footer__navigation,
.footer__navigation-mobile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer__navigation-mobile {
  display: none;
}

.footer__navigation li,
.footer__navigation-mobile li {
  list-style: none;
  margin-left: 64px;
}

.footer__navigation li a,
.footer__navigation-mobile li a {
  text-decoration: none;
  font-family: AvenirBlack;
  font-size: 18px;
  font-weight: 700;
  transition: 0.2s ease-in-out;
}

.footer__navigation li a:hover,
.footer__navigation-mobile li a:hover {
  color: var(--rose);
}

/* MaterialUI breakpoint `md` */
@media (max-width: 900px) {
  .footer {
    padding: 32px 0;
    margin-top: 72px;
  }
  .footer__first-row {
    flex-direction: column-reverse;
  }
  .footer__second-row div p {
    font-size: 11px;
    line-height: 13px;
  }
  .footer__message h4 {
    font-weight: 750;
    padding-bottom: 8px;
    line-height: 22px;
    font-size: 18px;
  }
  .footer__message p {
    font-weight: 600;
    font-size: 14px;
    width: 172px;
    line-height: 17px;
  }
  .footer__social-media p {
    font-weight: 500;
    font-family: 'AvenirBlack';
    line-height: 25px;
  }
  .footer__navigation-mobile {
    display: flex;
    padding: 49px 0;
    gap: 40px;
  }
  .footer__navigation {
    display: none;
  }
  .footer__navigation-mobile li {
    margin-left: 0;
  }
  .footer__navigation-mobile li a {
    font-size: 16px;
  }
}
